<template>
  <div class="activity-info">
    <h3>{{$t("activity.service")}}</h3>
    <p>{{$t("activity.describe")}}</p>
    <button class="btn btn-primary active" @click="$open('partake', $i18n.locale)">{{$t("activity.participate")}}</button>
  </div>
</template>

<script>
export default {
  data(){
    return {}
  }
}
</script>
<style scoped lang="scss">
.activity-info{
  background-size: 100% 100%;
  @include bg-image('../../assets/image/activity/activity-info');
  height: 640px;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  .btn.btn-primary.active{
    color: #0D73D6;
    background-color: #fff;
    border: none;
  }
  h3{
    font-size: 44px;
    color: #FFFFFF;
    line-height: 60px;
    margin-top: 192px;
  }
  p{
    margin-top: 16px;
    font-size: 18px;
    font-weight: 400;
    color: rgba($color: #fff, $alpha: 0.6);
    line-height: 25px;
  }
  button{
    margin-top: 47px;
  }
}

@media screen and (max-width: 768px) {
  .activity-info{
    width: 100vw;
    padding: 10px 15px;
    height: 320px;
    background-size: cover;
    background-position: center center;
    h3{
      width: 100%;
      height: 22.5px;
      line-height: 22.5px;
      text-align: center;
      color: #ffffff;
      font-size: 20px;
      font-weight: 600;
      margin-top: 80px;
    }
    p{
      width: 100%;
      height: auto;
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 20px;
      margin-top: 10px;
      text-align: center;
    }
    .btn{
      height: 30px;
      line-height: 30px;
      margin: 80px 2px 0 2px;
    }
  }
}
</style>