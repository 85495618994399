<template>
  <div>
    <NoticeSlider />
    <ActivityInfo />
    <ActivityDetail />
  </div>
</template>

<script>
import NoticeSlider from '@/components/activity/slider'
import ActivityInfo from '@/components/activity/activity-info'
import ActivityDetail from '@/components/activity/activity-detail'
export default {
  components:{
    NoticeSlider,
    ActivityInfo,
    ActivityDetail
  },
  data(){
    return {}
  }
}
</script>
<style scoped lang="scss">
</style>