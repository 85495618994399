<template>
  <div class="activity-detail">
    <h3>{{$t("activity.Detailed.title")}}</h3>
    <div class="content">
      <p>{{$t("activity.Detailed.describe1")}}</p>
      <p>{{$t("activity.Detailed.describe2")}}</p>
      <p>{{$t("activity.Detailed.describe3")}}</p>
      <p>{{$t("activity.Detailed.describe4")}}</p>
      <p>{{$t("activity.Detailed.describe5")}}</p>
      <div class="eg">{{$t("activity.Detailed.example")}}：</div>
      <p>{{$t("activity.Detailed.service")}}</p>
      <p>{{$t("activity.Detailed.describe6")}}</p>
      <p>{{$t("activity.Detailed.describe7")}}</p>
      <p>{{$t("activity.Detailed.describe8")}}</p>
      <p>{{$t("activity.Detailed.describe9")}}</p>
    </div>
    <div class="action"><button class="btn btn-primary" @click="$open('partake', $i18n.locale)">{{$t("activity.Detailed.participate")}}</button></div>
  </div>
</template>

<script>
export default {
  data(){
    return {}
  }
}
</script>
<style scoped lang="scss">
.activity-detail{
  padding-top: 1px;
  background-size: cover;
  @include bg-image('../../assets/image/activity/activity-bg');
  padding-bottom: 1px;
  h3{
    height: 50px;
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    line-height: 50px;
    text-align: center;
    margin: 80px 0 40px;
  }
  .content{
    width: 880px;
    margin: 0 auto;
    p{
      font-size: 16px;
      font-weight: 400;
      color: rgba($color: #000000, $alpha: 0.6);
      line-height: 32px;
    }
    .eg{
      font-size: 16px;
      font-weight: bold;
      color: #000000;
      line-height: 22px;
      margin-top: 40px;
      margin-bottom: 12px;
    }
  }
  .action{
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .activity-detail{
    width: 100vw;
    padding: 10px 15px;
    height: auto;
    background-size: cover;
    background-position: center center;
    h3{
      width: 100%;
      height: 22.5px;
      line-height: 22.5px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      margin-top: 20px;
    }
    .content{
      width: 100%;
      height: auto;
      overflow: hidden;
      p{
        width: 100%;
        height: auto;
        font-size: 13px;
        line-height: 20px;
        text-align: left;
      }
    }
    .action{
      margin-bottom: 20px;
    }
    .btn{
      height: 30px;
      line-height: 30px;

    }
  }
}
</style>